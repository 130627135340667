import React from "react";
import { Switch, Route } from "react-router-dom";

import { Navigation } from "./components/Navigation";
import { Footer } from "./components/Footer";
import { Home } from "./components/content/Home";
import { About } from "./components/content/About";
import { Osteopathy } from "./components/content/Osteopathy";
import { Homeopathy } from "./components/content/Homeopathy";
import { Massage } from "./components/content/Massage";
import { MedicinalLazer } from "./components/content/MedicinalLazer";
import { ContactAndBooking } from "./components/content/ContactAndBooking";

export const App = () => {
  return (
    <div className="vh-100 d-flex flex-column justify-content-between">
      <div>
        <Navigation />
        <Switch>
          <Route path="/om-mig" component={About} />
          <Route path="/osteopati" component={Osteopathy} />
          <Route path="/homeopati" component={Homeopathy} />
          <Route path="/massage" component={Massage} />
          <Route path="/medicinsk-laser" component={MedicinalLazer} />
          <Route path="/kontakt-bokning" component={ContactAndBooking} />
          <Route component={Home} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};
