import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";

export const Homeopathy = () => {
  return (
    <>
      <ImageBanner title="Homeopati" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="pt-4">
          <p className="p-0 mb-0">
            Homeopati är en välbeprövad metod inom alternativmedicinen som
            funnits i runt 200 år.
          </p>
          <p className="p-0 mb-0">
            Den homeopatiska konsultationen strävar efter att hitta grundorsaken
            till dina besvär. Behandlingen utgår ifrån dina fysiska,
            känslomässiga och mentala symptom.
          </p>
          <p className="p-0">
            Med hjälp av homeopatmedel stärker man upp immunförsvaret samt läker
            ut grundorsaken till dina besvär.
          </p>

          <p className="p-0 mb-0">
            Förstabesöket tar mellan 90-120 minuter. Där går vi igenom din
            sjukdomshistoria (anamnes) och aktuell problematik grundligt.
          </p>
          <p className="p-0 mb-0">
            Vanliga frågor du kommer att få är t.ex. när började besvären? finns
            det något som gör det sämre eller bättre? hur ser det dagliga
            mönstret ut? vad äter du för kost? hur sover du?
          </p>
          <p className="p-0 mb-0">
            Vilka mediciner och vacciner har du fått? opererad för något? vilken
            sorts smärta? osv.
          </p>
          <p className="p-0 mb-0">
            Utifrån den samlade symptombilden väljs det bäst passande
            homeopatmedlet ut.
          </p>
          <p className="p-0 mb-0">
            Medlet ges i pillerformat eller som droppar.
          </p>
          <p className="p-0">
            Efter 4-6 veckor görs ett återbesök för att utvärdera behandlingen
            och läkningsprocessen.
          </p>

          <p className="p-0 mb-0">Besvär som vanligen behandlas:</p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Reumatiska problem</li>
            <li>Trötthet och sömnbesvär</li>
            <li>PMS och menstruationsbesvär</li>
            <li>Allergier och astma</li>
            <li>Klimakteriebesvär</li>
            <li>Olika hudproblem</li>
            <li>Magproblematik</li>
            <li>Olika typer av huvudvärk inklusive migrän</li>
          </ul>

          <p className="p-0 mb-0">
            Förstabesök homeopatisk konsultation 90-120 min 1200 kr (inklusive
            homeopatmedel)
          </p>
          <p className="p-0">Återbesök ca 40 min 600 kr</p>

          <p className="p-0 mb-0">
            Jag är medlem i KHY, Klassiska homeopaters yrkesförbund.
          </p>
          <p className="p-0">
            För mer information om homeopati och förbundet se:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://klassiskahomeopater.se/"
            >
              https://klassiskahomeopater.se/
            </a>
          </p>

          <p className="p-0 mb-0">
            Är du intresserad av att läsa mer om aktuell forskning så kan du
            läsa vidare på:
          </p>
          <p className="p-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.homeopathy-ecch.org/homeopathy-in-europe/research/"
            >
              http://www.homeopathy-ecch.org/homeopathy-in-europe/research/
            </a>
          </p>

          <p className="p-0">
            Enligt svensk lag får homeopater inte behandla barn under åtta år,
            gravida kvinnor, cancer, diabetes, epilepsi och allvarliga
            smittsamma sjukdomar.
          </p>

          <div
            className="py-2"
            style={{
              backgroundColor: "rgba(184,212,182,1)",
              borderColor: "rgba(51,51,51,0.77)",
              borderStyle: "none",
              borderWidth: "1px 1px 1px 1px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              width: "fit-content",
            }}
          >
            <p className="p-0 mb-0">
              Bor du på annan ort eller av annan anledning har svårt att ta dig
              till kliniken så går det bra att göra konsultationen via skype.
            </p>
            <p className="p-0 mb-0">
              För skypekonsultation så mejla mig efter att du bokat tid.
            </p>
          </div>
          <div className="p-0 mx-0 my-4">
            <BookButton />
          </div>
        </Row>
      </Container>
    </>
  );
};
