import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Banner from "../images/banner.jpg";

export const ImageBanner = ({ title }) => {
  return (
    <div className="vw-100" style={{ height: "180px" }}>
      <img
        src={Banner}
        alt=""
        className="object-fit-cover position-absolute"
        style={{
          height: "inherit",
          width: "inherit",
          opacity: "90%",
        }}
      />
      <Container
        className="p-sm-0"
        style={{
          height: "inherit",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        <Row
          className="align-items-center"
          style={{
            height: "inherit",
          }}
        >
          <h1
            className="text-white col-lg-8 pt-2 pt-lg-0 px-0"
            style={{ zIndex: 2 }}
          >
            {title}
          </h1>
          <h2
            className="text-white col-lg-auto pb-2 pb-lg-0 px-0"
            style={{ zIndex: 2, fontWeight: 200 }}
          >
            Osteopat
            <br />
            Homeopat
            <br />
            Massageterapeut
          </h2>
        </Row>
      </Container>
    </div>
  );
};
