import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Nova from "../../images/NOVA.jpg";
import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";

export const Home = () => {
  return (
    <>
      <ImageBanner title="Lundhs Friskvård" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="py-4" style={{ fontSize: "2rem" }}>
          Välkommen!
        </Row>
        <Row>
          Jag heter Nikodemus Lundh och är utbildad Osteopat, Homeopat och
          massageterapeut. Lundhs friskvård hittar du på Lerums Hälsoklinik.
        </Row>
        <Row>
          <BookButton />
        </Row>
        <Row>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.novahealthsupport.se"
            className="a-unstyled d-flex flex-column align-items-center my-4 p-0"
            style={{ fontSize: "0.75rem", width: "9rem" }}
          >
            <img src={Nova} alt="Hälsofjärilen NOVA" height="60" width="60" />
            Lundhs Friskvård ingår i NOVA Health Supports Hälsonätverk
          </a>
        </Row>
      </Container>
    </>
  );
};
