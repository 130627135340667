import React from "react";

export const BookButton = () => {
  return (
    <a
      className="p-0 my-4"
      style={{ width: "146px" }}
      href="https://www.bokadirekt.se/places/lerums-hälsoklinik-45707"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://foretag.bokadirekt.se/bokatid/BokaTid_Gron_MorkBakgrund_120px.png"
        alt="Boka tid"
        border="0"
      />
    </a>
  );
};
