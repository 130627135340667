import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";

export const Massage = () => {
  return (
    <>
      <ImageBanner title="Klassisk Svensk Massage" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="pt-4">
          <p className="p-0">
            Hos mig kan du få både avslappnande och riktad massagebehandling.
          </p>

          <p className="p-0 mb-0">Massage:</p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Främjar blod- och lymfcirkulation</li>
            <li>Minskar muskulära spänningar</li>
            <li>Sänker stress</li>
            <li>Ger en snabbare återhämtning efter ansträngning</li>
            <li>Stärker immunförsvaret</li>
            <li>Minskar smärta och värk</li>
            <li>Frigör "må-bra" hormoner</li>
            <li>Ger ökad avslappning</li>
            <li>Underlättar elimination av slaggprodukter</li>
          </ul>

          <p className="p-0 mb-0">Gravidmassage:</p>
          <p className="p-0">
            Är du intresserad av massage och är gravid i vecka 13 eller senare
            så kan du gå på gravidmassage. Vi anpassar behandlingen efter dina
            behov och önskemål. Då det blir svårt att ligga på mage så kan man
            få massage sittandes eller liggandes på sidan med stöd av kuddar.
          </p>

          <p className="p-0 mb-0">Massage 25 min 400 kr</p>
          <p className="p-0 mb-0">Massage 50 min 700 kr</p>
          <p className="p-0 mb-0">
            Är du hälsoresurskund så kan du använda ditt klippkort för massage
            hos mig.
          </p>

          <BookButton />
        </Row>
      </Container>
    </>
  );
};
