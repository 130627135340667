import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export const Navigation = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(history.location.pathname);
  const [expanded, setExpanded] = useState(false);

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <div style={{ height: "56px" }}>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        fixed="top"
        expanded={expanded}
      >
        <Container
          className="p-sm-0"
          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
        >
          {/* <Navbar.Brand href="/">Lundhs Friskvård</Navbar.Brand> */}
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" variant="underline" activeKey={activeKey}>
              <Nav.Item>
                <Nav.Link
                  eventKey="/"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/");
                    history.push("/");
                  }}
                >
                  Hem
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="/om-mig"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/om-mig");
                    history.push("/om-mig");
                  }}
                >
                  Om mig
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="/osteopati"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/osteopati");
                    history.push("/osteopati");
                  }}
                >
                  Osteopati
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="/homeopati"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/homeopati");
                    history.push("/homeopati");
                  }}
                >
                  Homeopati
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="/massage"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/massage");
                    history.push("/massage");
                  }}
                >
                  Massage
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="/medicinsk-laser"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/medicinsk-laser");
                    history.push("/medicinsk-laser");
                  }}
                >
                  Medicinsk Laser
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="/kontakt-bokning"
                  onClick={() => {
                    setExpanded(false);
                    scrollToTop();
                    setActiveKey("/kontakt-bokning");
                    history.push("/kontakt-bokning");
                  }}
                >
                  Kontakt & Bokning
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
