import React from "react";

import { ContactInfo } from "./ContactInfo";

export const Footer = () => {
  return (
    <footer className="pt-3 px-3 mt-3 mb-1 border-top d-flex justify-content-center">
      <ContactInfo />
    </footer>
  );
};
