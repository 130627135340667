import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";

export const MedicinalLazer = () => {
  return (
    <>
      <ImageBanner title="Medicinsk Laser" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="pt-4">
          <p className="p-0 mb-0">
            Jag använder oftast medicinsk laser som ett komplement till den
            osteopatiska behandlingen samt massage.
          </p>
          <p className="p-0">
            Det går även att boka tid för enbart en laserbehandling.
          </p>

          <p className="p-0 mb-0">Medicinsk laser</p>
          <ul className="mb-0" style={{ paddingLeft: "2rem" }}>
            <li>Stimulerar cellfunktioner</li>
            <li>Minskar inflammation</li>
            <li>Reducerar ödem och svullnad</li>
            <li>Påskyndar läkning</li>
            <li>Minskar smärta</li>
          </ul>

          <BookButton />
        </Row>
      </Container>
    </>
  );
};
