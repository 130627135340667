import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Nikodemus from "../../images/nikodemus.jpg";
import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";

export const About = () => {
  return (
    <>
      <ImageBanner title="Nikodemus Lundh" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="py-4">
          <div className="col-lg-6 p-0">
            <ul style={{ paddingLeft: "1rem" }}>
              <li>
                Osteopat D.O. Bsc. Skandinaviska osteopathögskolan 2016-2020.
              </li>
              <li>Homeopat, Arcanumskolan 2009-2012</li>
              <li>Massageterapeut cert. Axelsons gymnastiska institut 2012.</li>
            </ul>
            <BookButton />
          </div>
          <img
            src={Nikodemus}
            alt="Nikodemus Lundh"
            className="col-6 object-fit-cover m-3"
            style={{ objectPosition: "50% 25%", width: "400px" }}
          />
        </Row>
      </Container>
    </>
  );
};
