import React from "react";

import { ReactComponent as TelephoneFill } from "../icons/telephone-fill.svg";
import { ReactComponent as EnvelopeFill } from "../icons/envelope-fill.svg";
import { ReactComponent as GeoAltFill } from "../icons/geo-alt-fill.svg";
import { ReactComponent as Facebook } from "../icons/facebook.svg";

export const ContactInfo = () => {
  return (
    <ul className="list-unstyled text-left">
      <li className="m-1">
        <a href="tel:0708-883229" className="a-unstyled">
          <TelephoneFill />
          <span className="p-2">0708-883229</span>
        </a>
      </li>
      <li className="m-1">
        <a href="mailto:lundhsfriskvard@gmail.com" className="a-unstyled">
          <EnvelopeFill />
          <span className="p-2">lundhsfriskvard@gmail.com</span>
        </a>
      </li>
      <li className="m-1">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/maps/search/?api=1&query=Lundhs%20Friskv%C3%A5rd%2C%20G%C3%B6teborgsv%C3%A4gen%203%2C%20443%2030%20Lerum%2C%20Sweden"
          className="a-unstyled"
        >
          <GeoAltFill />
          <span className="p-2">
            Lundhs Friskvård, Göteborgsvägen 3, 443 30 Lerum
          </span>
        </a>
      </li>
      <li className="m-1">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://facebook.com/LundhsFriskvard/?fref=nf"
          className="a-unstyled"
        >
          <Facebook />
          <span className="p-2">Lundhs Friskvård på Facebook</span>
        </a>
      </li>
    </ul>
  );
};
