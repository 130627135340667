import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Map from "../../images/map.png";
import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";
import { ContactInfo } from "../ContactInfo";

export const ContactAndBooking = () => {
  return (
    <>
      <ImageBanner title="Kontakt och Bokning" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="py-4">
          <div className="col-lg-6 p-0">
            <p className="p-0">
              Öppetider är varierande. Bokning görs enklast via "Boka tid"
              knappen nedan.
            </p>

            <p className="p-0 mb-0">
              Hittar du ingen tid som passar dig så är du varmt välkommen att
              kontakta mig via sms, telefon eller mejl.
            </p>
            <p className="p-0">
              Lundhs friskvård finns på Lerums Hälsoklinik som befinner sig på
              gångavstånd till både Lerums centrum samt station och Aspedalen
              station.
            </p>

            <p className="p-0">
              Har du friskvårdsbidrag via ditt arbete så går det bra att
              använda. Om du via ditt arbete är ansluten till ePassi så går även
              den tjänsten att utnyttja. Jag är också ansluten till Hälsoresurs
              för massagebehandlingar.
            </p>

            <BookButton />
            <div className="mt-3">
              <ContactInfo />
            </div>
          </div>
          <img
            src={Map}
            alt="Karta till kliniken"
            className="col-6 object-fit-cover"
            style={{ objectPosition: "50% 25%", width: "400px" }}
          />
        </Row>
      </Container>
    </>
  );
};
