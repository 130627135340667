import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { BookButton } from "../BookButton";
import { ImageBanner } from "../ImageBanner";

export const Osteopathy = () => {
  return (
    <>
      <ImageBanner title="Osteopati" />
      <Container
        className="p-sm-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <Row className="pt-4">
          <p className="p-0">
            Osteopati är en form av manuell terapi med fokus på hela individen.
            För osteopaten är god cirkulation, rörelse, hållning, stabilitet och
            avslappning samt vila viktiga delar för att kroppen ska kunna läka
            och bibehålla balans.
          </p>

          <p className="p-0 mb-0">
            Ett förstabesök tar vanligen lite längre tid då man går igenom
            sjukdomshistoriken (anamnestagning) och de besvär du söker för.
          </p>
          <p className="p-0 mb-0">
            Efter samtalet görs en undersökning som börjar med stående eller
            sittande observation.
          </p>
          <p className="p-0 mb-0">
            Sedan följer rörelsetester och eventuella ortopediska samt
            neurologiska tester och palpation av ömma muskler och leder.
          </p>
          <p className="p-0">
            De fynd som görs leder fram till en diagnos och en behandlingsplan
            som är individuellt anpassad efter dina behov och förutsättningar.
          </p>

          <p className="p-0">
            Utöver behandling får man vanligtvis med sig råd om kost, ergonomi,
            sömn, motion och hemövningar.
          </p>

          <p className="p-0">
            Ibland räcker det med en behandling medans andra behöver
            återkommande behandlingar beroende på vilken typ av besvär det
            handlar om. Ofta kombineras osteopati, massage och laser samt
            rörelseträning. För andra som kanske haft problem under en längre
            tid eller har en underliggande sjukdom eller skada så kan det krävas
            flera behandlingar.
          </p>

          <p className="p-0 mb-0">
            Vanliga besvär som många uppsöker en osteopat för:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Ryggskott</li>
            <li>Smärtor och stelhet i rygg, nacke och axlar</li>
            <li>Nackspärr</li>
            <li>Isciassmärta</li>
            <li>Ömma muskler</li>
            <li>Spänningshuvudvärk och migrän</li>
            <li>Musarm</li>
            <li>Tennis och golfarmbåge</li>
            <li>Smärtor och problem i höfter, knän och fötter</li>
            <li>Karpaltunnelsyndrom</li>
            <li>Diskbråck</li>
            <li>Artros</li>
            <li>Ledproblematik</li>
          </ul>

          <p className="p-0 mb-0">Osteopatisk behandling 40 minuter</p>
          <p className="p-0">Pris per behandling 800kr</p>

          <p className="p-0 mb-0">
            Jag är medlem i SOF, Svenska Osteopatförbundet.
          </p>
          <p className="p-0 mb-0">
            Vill du läsa mer om osteopati eller förbundet?
          </p>
          <p className="p-0 mb-0">
            Se:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.osteopatforbundet.se/om-osteopati/"
            >
              https://www.osteopatforbundet.se/om-osteopati/
            </a>
          </p>
          <BookButton />
        </Row>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            paddingTop:
              "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
          }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/lHXXwq3d2rQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      </Container>
    </>
  );
};
